<template>
  <div class="container-fluid pt-3">
    <div class="gws_content_container p-3">
      <!-- title -->
      <div class="row g-3">
        <div class="col-md-12">
          <h1 class="text-center mb-0">{{ t("nav.festivaldata") }} {{ t("general.edit") }}</h1>
          <div class="subtitle-name mb-3 text-center">{{ getDisplayName(address) }}</div>
        </div>
      </div>
      <!-- nav -->
      <div class="row g-3">
        <div class="col-md-12">
          <AddressNavigation :categories="address.categories" :id="$route.params.id"></AddressNavigation>
        </div>
      </div>
      <!-- resluts -->
      <div class="gws_list_inputs mt-4">
        <template v-for="(fesitval, index) in fesitvalData" :key="'fesitval_list_' + index">
          <div class="row">
            <div class="col-md-11">
              <div class="row mb-md-1 pb-md-1">
                <div class="col-md-3 pt-2 pt-md-0 d-flex flex-column justify-content-between">
                  <label :for="'fesitval_start_at_' + fesitval.id" class="form-label"
                    >Start-{{ t("general.date") }}</label
                  >
                  <input
                    v-model="fesitvalData[index].start_at"
                    type="date"
                    class="form-control"
                    :id="'fesitval_start_at_' + fesitval.id"
                  />
                </div>
                <div class="col-md-3 pt-2 pt-md-0 d-flex flex-column justify-content-between">
                  <label :for="'fesitval_end_at_' + fesitval.id" class="form-label">End-{{ t("general.date") }}</label>
                  <input
                    v-model="fesitvalData[index].end_at"
                    type="date"
                    class="form-control"
                    :id="'fesitval_end_at_' + fesitval.id"
                  />
                </div>
                <div class="col-md-3 pt-2 pt-md-0 d-flex flex-column justify-content-between">
                  <label :for="'fesitval_booking_start_' + fesitval.id" class="form-label">Start Booking</label>
                  <input
                    v-model="fesitvalData[index].booking_start"
                    type="date"
                    class="form-control"
                    :id="'fesitval_booking_start_' + fesitval.id"
                  />
                </div>
                <div class="col-md-3 pt-2 pt-md-0 d-flex flex-column justify-content-between">
                  <label :for="'fesitval_booking_end_' + fesitval.id" class="form-label">Ende Booking</label>
                  <input
                    v-model="fesitvalData[index].booking_end"
                    type="date"
                    class="form-control"
                    :id="'fesitval_booking_end_' + fesitval.id"
                  />
                </div>
              </div>
            </div>
            <div class="col-md-1 gws_list_inputs__controls">
              <button
                @click="removeFestivalData(index)"
                class="btn btn-danger btn-icon mt-2 mt-lg-0"
                :title="t('general.remove')"
              >
                <svg class="bi" width="20" height="20" fill="currentColor">
                  <use xlink:href="/assets/img/bootstrap-icons.svg#trash-fill"></use>
                </svg>
              </button>
            </div>
          </div>
        </template>
      </div>
      <div class="d-flex justify-content-center align-items-center gap-2 p-2 my-2">
        <button @click="addFestivalData" class="btn btn-primary btn-icon">
          <svg width="20" height="20" class="bi me-1" fill="currentColor">
            <use xlink:href="/assets/img/bootstrap-icons.svg#plus-circle-fill"></use>
          </svg>
          {{ t("general.add") }}
        </button>
      </div>
      <!-- control_bar -->
      <div class="gws_control_bar d-flex justify-content-center align-items-center gap-2 p-2 my-2">
        <button @click="save" class="btn d-block btn-success">
          <svg width="24" height="24" class="bi me-1" fill="currentColor">
            <use xlink:href="/assets/img/bootstrap-icons.svg#check"></use>
          </svg>
          {{ t("general.save") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n/index";
import { useApiQuery } from "@/composables/useApiQueries";
import { getDisplayName } from "@/composables/address";
import axios from "axios";
import storeConfig from "../../store/config";
import { useToast } from "vue-toastification";
import AddressNavigation from "@/components/navigation/Address.vue";
const router = useRouter();
const route = useRoute();
const { t } = useI18n();
const store = useStore();
const toast = useToast();
const currentUser = computed(() => store.getters.user);
const { result: address, query: getAddress } = useApiQuery({
  url: "addresses/" + route.params.id,
  defaultVal: [],
});
const { result: fesitvalData, query: getFestivalData } = useApiQuery({
  url: "search/festivaldata/byfestival",
  defaultVal: [],
  params: {
    params: {
      id: route.params.id,
    },
  },
  pfn: function (data) {
    return data.map((festival) => {
      if (festival?.start_at) {
        festival.start_at = festival.start_at.substr(0, 10);
      }
      if (festival?.end_at) {
        festival.end_at = festival.end_at.substr(0, 10);
      }
      if (festival?.booking_start) {
        festival.booking_start = festival.booking_start.substr(0, 10);
      }
      if (festival?.booking_end) {
        festival.booking_end = festival.booking_end.substr(0, 10);
      }
      return festival;
    });
  },
});

// add new festival
const addFestivalData = async () => {
  fesitvalData.value.push({
    created_by: currentUser.value.id,
    updated_by: currentUser.value.id,
    festival_id: route.params.id,
    start_at: "",
    end_at: "",
    booking_start: "",
    booking_end: "",
    musicgenre: null,
    comment: null,
  });
};

const removedFestivalData = [];
const removeFestivalData = (index) => {
  if (fesitvalData.value[index].id) {
    removedFestivalData.push(fesitvalData.value[index].id);
  }
  fesitvalData.value.splice(index, 1);
};

async function save() {
  const data = fesitvalData.value.map((festival) => {
    festival.updated_by = currentUser.value.id;
    return festival;
  });
  const newData = data.filter((festival) => !festival.id);
  const updatedData = data.filter((festival) => festival.id);

  try {
    await store.dispatch("addLoading", 1);
    // delete
    if (removedFestivalData.length > 0) {
      await axios.delete(storeConfig.apiBaseUrl + "mcud/festivaldata", {
        data: {
          ids: removedFestivalData,
        },
      });
    }
    // create
    if (newData.length > 0) {
      await axios.post(storeConfig.apiBaseUrl + "mcud/festivaldata", newData);
    }
    // update
    if (updatedData.length > 0) {
      await axios.put(storeConfig.apiBaseUrl + "mcud/festivaldata", updatedData);
    }
    toast.success(t("nav.festivaldata") + " " + t("general.successfully") + " " + t("general.edited") + ".");
  } catch (error) {
    if (error.response.status == 401) {
      store.dispatch("logout");
      router.push({ name: "Login" });
    }
  } finally {
    await store.dispatch("addLoading", -1);
  }
}
getAddress();
getFestivalData();
</script>
